//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-900:_1276,_7384,_3172,_9637,_4056,_8424,_3864,_1484;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-900')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-900', "_1276,_7384,_3172,_9637,_4056,_8424,_3864,_1484");
        }
      }catch (ex) {
        console.error(ex);
      }